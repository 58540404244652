.navi-bar {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 2 !important;
    background-color: #1C1C1C !important;

    button {
        text-transform: none;
        font-family: "Lato", serif;
    }

    .nav_button_link {
        text-transform: none;
    }

    .logo_img {
        height: 30px;
    }

    .navbar-avatar {
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}

.main_app_container {
    max-width: 4000px !important;
}

footer {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);

    svg {
        color: white;
        padding: 5px;
    }
}