.movie_app_root {
    margin: 80px 25px 15px 25px;
    font-weight: 400;
    font-style: normal;
    min-height: 750px;
}

.profile_container {
	display: flex;
	gap: 20px;
	padding: 10px;
	background-color: #303134 !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 10px #00000026, 0 1px 1px #0000001a !important;
	margin-top: 16px;
	margin-bottom: 15px;
	cursor: pointer;

	img {
		border-radius: 50%;
		width: 56px;
		height: 56px;
		box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
	}

	h1 {
		font-size: 1.2em;
		margin-block-start: 0px !important;
    	margin-block-end: 10px !important;
	}

	span {
		color: #b9bdcb !important;
	}

	.followers_numbers {
		display: flex;
		flex-direction: row;
		gap: 15px;

		span {
			font-size: 0.875rem;
		}
	}

	.user_not_authorized {
		color: #b9bdcb;

		span {
			color: #42a5f5 !important;
			cursor: pointer;
		}

		span:hover {
			text-decoration: underline;
		}
	}
}

.menu_button {
	button {
		width: 100%;
		text-align: left;
		justify-content: flex-start;
		background-color: transparent;
		text-transform: capitalize;
		border-radius: 5px;
		box-shadow: none;
	}

	button:hover {
		background-color: #b9bdcb;
		color: black;
	}
}
  
.header {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  
    h1 {
		margin-block-start: 0px;
		margin-block-end: 0px;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-optical-sizing: auto;
		font-weight: 600;
		font-style: normal;
    }
  
    svg {
		vertical-align: middle;
		font-size: 2.5em;
    }
  
    button {  
		border-radius: 5px;
		background-color: black;
		font-optical-sizing: auto;
		font-weight: 600;
		font-style: normal;
    }
  
    button:hover {
      	background-color: gray;
    }
}

.dashboard_left {
	width: 20%;
	max-width: 20%;
    position: fixed;
    left: 0;
    padding-left: 10px;
}
  
.dashboard_middle {
	margin-top: 15px !important;
	// max-width: 58.6%;
	max-width: 80%;
	width: 100%;
}

.dashboard_right {
	margin-top: 15px !important;
	max-width: 20%;
	width: 100%;
    position: fixed;
    right: 0;
    padding-right: 10px;
	height: 100%;
    overflow-x: auto;
	display: block;
}
  
.search_form_wrapper {
	margin-bottom: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: '100%';
	position: 'relative';

	form div {
		color: #B9BDCB !important;
		height: 48px !important;
	}

	svg {
		color: #B9BDCB;
	}
}
  
.search_form {
	border-radius: 5px !important;
	background-color: #303134 !important;
	padding: 0px !important;
	box-shadow: 0px 1px 10px rgba(0,0,0,0.15), 0 1px 1px rgba(0,0,0,0.1) !important;
}
  
input {
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
  
.result_container {
	margin-bottom: 50px;

	.card_info {
		h2 {
			margin-block-start: 0px;
			margin-block-end: 0px;
		}
	}
}
  
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
	font-family: "Lato", sans-serif !important;
}
  
.dropdown_form, .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
	background-color: #303134 !important;
	color: #B9BDCB !important;

	label, div {
		color: #B9BDCB;
	}
}

.margin_top {
	margin-top: 15px !important;
}
  
.movie_img img {
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
	aspect-ratio: 2/3;
}
  
.image_fill {
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
	aspect-ratio: 2/3;
}

.zoom {
	border-radius: 5px;
	transition: transform .2s !important;
	margin: 0 auto;
}

.zoom:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.loading {
	display: flex;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 15px;
}
  
input {
	font-family: "Lato", sans-serif !important;
}

.alert_container {
	border-radius: 5px !important;
	background-color: #3a3b3f !important;
	font-family: "Lato", sans-serif !important;

	svg {
		color: gray;
	}

	div, span {
		color: gray;
		font-optical-sizing: auto;
		font-weight: 400;
		font-style: normal;
	}

	span, p {
		font-size: 0.875rem !important;
		font-family: "Lato", sans-serif !important;
	}
  
    .instruction {
      font-size: 0.875rem !important;
    }
}
  
.filter_list {
	padding-left: 0px !important;
    padding-right: 7px !important;

    svg {
		display: none;
    }
}
  
.accordion_container {
    background-color: #303134 !important;
	border-radius: 5px !important;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 15px !important;

	p {
		font-family: "Lato", sans-serif !important;
	}
  
    label, div {
		color: #B9BDCB !important;
    }
  
    span {
		color: #B9BDCB !important;
    }
  
    .accordion_label {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #B9BDCB;
	
		svg {
			fill: #ff9a26;
		}
    }
}

.accordion_details_container {
	padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 0px !important;
}

.button_container {
	button {
		border-radius: 5px;
	}
}

.MuiAccordionSummary-content, .MuiFormControlLabel-label, .accordion_label {
	font-size: 0.875rem !important;
}

.mobile_filters_drawer {
	padding: 15px;
	background-color: #1c1c1c;

	.mobile_filters {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

.mui-drawer_mobile .MuiDrawer-paper {
	background-color: #1c1c1c;
}

.mobile_search_wrapper {
	display: none;
	width: 100%;
}

@media screen and (max-width: 1200px) {
	.movie_app_root {
		margin-left: 15px;
		margin-right: 15px;
	}

	.mobile_grid_container {
		margin: 0 !important;
    	width: 100% !important;
	}

	.mobile_search_wrapper {
		display: block;
		width: 100%;
	}

    .sidebar_filter {
        padding-top: 0px !important;
		display: none;
    }

	.dashboard_middle {
		margin-left: initial;
		max-width: 100%;
	}

	.dashboard_right {
		display: none;
	}

	.accordion_label {
		font-size: 0.875rem;
	}

	.search_form_wrapper {
		margin-bottom: 10px;
		width: 100%;
		position: relative;

		.search_form {
			margin-right: 10px !important;
		}
	}

	.mobile_filter_btn {
		height: 48px;
		background-color: #303134 !important;
		border-radius: 5px !important;
		box-shadow: 0 1px 10px #00000026, 0 1px 1px #0000001a !important;

		span {
			margin: 0 !important;
		}

		svg {
			font-size: 25px !important;
		}
	}

	.button_container {
		padding-bottom: 10px;

		button {
			border-radius: 5px;
		}
	}

	.apply_filter_btn {
		padding-left: 5px !important;
	}

	.MuiAccordionSummary-content {
		font-size: 0.875rem;
	}

	.accordion_container {
		margin-bottom: 10px !important;

		div {
			min-height: 15px;
		}
	}
}